import './footer.scss'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Logo</a>
      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#testimonial">Testimonials</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://facebook.com" target="_blank">Social Link</a>
        <a href="https://facebook.com" target="_blank">Social Link</a>
        <a href="https://facebook.com" target="_blank">Social Link</a>
      </div>

      <div className="footer__copyright">
        <small>
          &copy; EGATOR Tutorials. All rights reserved.
        </small>
      </div>
    </footer>
  )
}
export default Footer