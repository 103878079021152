import './contact.scss'
import { MdOutlineEmail} from 'react-icons/md'
import {RiDiscordLine} from 'react-icons/ri'
import React, { useRef } from 'react'
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    e.target.reset();
  };


  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>sander.bewerbung@gmx.de</h5>
            <a href="mailto:sander.bewerbung@gmx.de" target="_blank">Send a Message</a>
          </article>
          <article className='contact__option'>
            <RiDiscordLine className='contact__option-icon' />
            <h4>Messenger</h4>
            <h5>discord name</h5>
            <a href="mailto:sander.bewerbung@gmx.de" target="_blank">Send a Message</a>
          </article>
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Whatsapp</h4>
            <a href="https://api.whatsapp.com/send?phone=+529982220046" target="_blank">Send a Message</a>
          </article>
        </div>
        {/* End Of Contact Options */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Full Name' required />
          <input type="email" name='email' placeholder='Your E-Mail' required />
          <textarea name="message" placeholder="Your Message" rows="7" required></textarea>
          <button className="btn btn-primary" type="submit">Send Message</button>
        </form>
      </div>
    </section>
  )
}
export default Contact