import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {ImXing} from 'react-icons/im'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://linkedin.com" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com" target="_blank"><FaGithub /></a>
        <a href="https://xing.com" target="_blank"><ImXing /></a>
        {/* <a href="https://dribble.com" target="_blank"></a> */}
    </div>
  )
}
export default HeaderSocials